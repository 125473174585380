import React from 'react'
import { graphql } from 'gatsby'
import { withIntl } from '../i18n'

import Layout from '../components/Layout'
import ContentBlock from '../components/ContentBlock'
import Img from 'gatsby-image'

const AboutSharmilaPage = props => {

  const images = props.data.allImagesJson.edges[0].node.childrenImagesJson.reduce((acc, curr) => {
    acc.push(curr.file.childImageSharp.fluid)
    return acc
  }, [])

  return (
    <Layout>
      <ContentBlock content={['about.sharmila.subtitle']} specialClass='header' />

      <ContentBlock content={['about.sharmila.1', 'about.sharmila.2']} />

      <div className="photoRow doubleColumn">
        {images.map((t) => (
          <div className="photoColumn">
            <Img fluid={t} />
          </div>
        ))}
      </div>

    </Layout>
  )
}

export const AboutSharmilaImageQuery = graphql`
  query AboutSharmilaImageQuery {
    allImagesJson: allFile(
      filter: { relativePath: { eq: "grid/sharmila/images.json" } }
    ) {
      edges {
        node {
          childrenImagesJson {
            file {
              childImageSharp {
                fluid(maxWidth: 2280) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`

export default withIntl(AboutSharmilaPage)
